import React from 'react';
import ReactDOM from 'react-dom';
import IngredientRowExtended from './IngredientRowExtended';
import { getNewIngredient } from '../../models/Product';

class ComponentTableExtended extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            weight_loss: this.props.weight_loss,
            ingredients: this.props.ingredients,
            totals: {
                grams: 0,
                final_grams: 0,
                energy: 0,
                protein: 0,
                total_fat: 0,
                sat_fat: 0,
                mono_fat: 0,
                poli_fat: 0,
                trans_fat: 0,
                cholesterol: 0,
                carbohydrates: 0,
                sugar: 0,
                sodium: 0
            },
            new: {
                id: 0,
                name: "",
                grams: 0
            },
            catalogue: this.props.catalogue,
            shouldUpdate: false
        }

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleFinalGramsChange = this.handleFinalGramsChange.bind(this);
        this.handleGramsChange = this.handleGramsChange.bind(this);
        this.handleAddIngredientChange = this.handleAddIngredientChange.bind(this);
        this.handleAddIngredientSubmit = this.handleAddIngredientSubmit.bind(this);
        this.handleRemoveIngredient = this.handleRemoveIngredient.bind(this);
        this.handleDestroy = this.handleDestroy.bind(this);

        setInterval(() => { this.props.parentUpdateComponent(this.props.id, this.state.totals); }, 2000);
    }

    componentDidMount() {
        this.updateTotals();
    }

    componentDidUpdate() {
        if (this.state.shouldUpdate) {
            this.updateTotals();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.catalogue != state.catalogue) {
            return { ...state, catalogue: props.catalogue, shouldUpdate: true };
        }
        return null;
    }

    updateTotals(force = false) {
        this.setState({
            ...this.state,
            shouldUpdate: false,
            totals: {
                ...this.state.totals,
                grams: this.getTotalGrams(),
                energy: this.getPropertyTotal('energy'),
                protein: this.getPropertyTotal('protein'),
                total_fat: this.getPropertyTotal('total_fat'),
                sat_fat: this.getPropertyTotal('sat_fat'),
                mono_fat: this.getPropertyTotal('mono_fat'),
                poli_fat: this.getPropertyTotal('poli_fat'),
                trans_fat: this.getPropertyTotal('trans_fat'),
                cholesterol: this.getPropertyTotal('cholesterol'),
                carbohydrates: this.getPropertyTotal('carbohydrates'),
                sugar: this.getPropertyTotal('sugar'),
                sodium: this.getPropertyTotal('sodium')
            },
        }, () => { this.updateFinalGrams(force) });
    }

    updateFinalGrams(force = false) {
        let finalGrams = this.state.totals.final_grams;

        if (force) {
            finalGrams = this.state.totals.grams;
        } else if (finalGrams <= 0 || finalGrams > this.state.totals.grams) {
            finalGrams = parseInt(this.state.totals.grams * (100 - this.props.weight_loss) / 100);
        }

        this.setState({
            ...this.state,
            totals: { ...this.state.totals, final_grams: finalGrams }
        }, () => { this.updateWeightLoss() });
    }

    updateWeightLoss() {
        let weightLoss = 0;
        if (this.state.totals.grams > 0) {
            weightLoss = 100 - ((this.state.totals.final_grams * 100) / this.state.totals.grams);
        }

        this.setState({
            ...this.state,
            weight_loss: weightLoss
        });
    }

    getTotalGrams() {
        let total = 0;
        if (this.state.ingredients != null && this.state.ingredients.length > 0) {
            total = this.state.ingredients.reduce((sum, item) => {
                return sum + parseInt(item.pivot.grams);
            }, 0);
        }

        return Math.round(total);
    }

    getWeightedTotalGrams() {
        return Math.round(this.state.totals.grams * (1 - (this.state.weight_loss / 100)));
    }

    getPropertyTotal(prop) {
        let total = 0;

        if (this.state.ingredients != null && this.state.ingredients.length > 0) {
            total = this.state.ingredients.reduce((sum, item) => {
                return sum + parseFloat(this.getValueForProp(item.id, prop) / 100 * item.pivot.grams);
            }, 0);
        }

        return parseFloat(total);
    }

    handleGramsChange(event) {
        let update = this.state.ingredients.map((item) => {
            if (item.id == event.id) {
                item.pivot.grams = event.grams;
            }
            return item;
        }, event);

        this.setState(
            { ...this.state, ingredients: update },
            () => { this.updateTotals(true) }
        );
    }

    handleAddIngredientChange(event) {
        if (event.target.id === "newIngredientId") {
            this.setState({ ...this.state, new: { ...this.state.new, id: event.target.value } });
        }

        if (event.target.id === "newIngredientName") {
            this.setState({ ...this.state, new: { ...this.state.new, name: event.target.value } });
        }

        if (event.target.id === "newIngredientGrams") {
            this.setState({ ...this.state, new: { ...this.state.new, grams: event.target.value } });
        }
    }

    handleAddIngredientSubmit(event) {
        event.preventDefault();

        if (this.state.new.grams > 0) {
            let ingredient = null;

            if (this.state.new.name && this.state.new.name != "") {
                ingredient = Object.assign({}, getNewIngredient());
                ingredient.id = (-1 * new Date().getTime());
                ingredient.name = this.state.new.name;
                ingredient.is_component = true;
                ingredient.pivot.grams = this.state.new.grams;
            } else if (this.state.new.id > 0) {
                let search = this.state.catalogue.filter(item => item.id == this.state.new.id);
                if (search.length === 0) {
                    return;
                }
                ingredient = search[0];
                ingredient['pivot'] = { grams: this.state.new.grams };
            }

            if (ingredient != null) {
                this.setState({
                    ...this.state,
                    new: {
                        id: 0,
                        grams: 0,
                        name: ""
                    },
                    ingredients: [ ...this.state.ingredients, ingredient ]
                }, () => { this.updateTotals(true) });

                this.props.parentAddIngredient(this.props.id, ingredient);
            }
        }
    }

    handleRemoveIngredient(event) {
        event.preventDefault();

        let id = event.target.dataset.id;
        let ingredients = [...this.state.ingredients].filter(item => item.id != id, id);
        this.setState(
            { ...this.state, ingredients: ingredients },
            () => { this.updateTotals(true) }
        );

        this.props.parentDeleteIngredient(this.props.id, id);
    }

    handleNameChange(event) {
        let value = event.target.value;
        this.setState({ ...this.state, name: value });
    }

    handleFinalGramsChange(event) {
        let value = event.target.value == "" || isNaN(event.target.value) ? 0 : parseInt(event.target.value);

        if (value >= 0) {
            this.setState({
                ...this.state,
                totals: { ...this.state.totals, final_grams: value }
            }, () => { this.updateWeightLoss() });
        }
    }

    handleDestroy(event) {
        event.preventDefault();
        this.props.parentDeleteComponent(this.props.id);
    }

    getValueForProp(id, prop) {
        if (this.state.catalogue.length == 0) {
            return 0;
        }

        let product = this.state.catalogue.filter(item => item.id == id, id)[0];

        return product ? product[prop] : 0;
    }

    render () {
        return <div className="card bg-light mb-5">
            <div className={ 'card-header py-2 ' + (this.props.canBeDeleted ? 'bg-secondary' : 'bg-primary') }>
                <div className="clearfix">
                    <h6 className="m-0 font-weight-bold btn-icon-split align-bottom text-white">
                        { this.props.canBeDeleted ? <span><i className="fa fa-puzzle-piece mr-2"></i> Componente (Preparación del ingrediente)</span> : <span><i className="fa fa-gift mr-2"></i> Producto Final</span> }
                    </h6>
                    {this.props.canBeDeleted ? <button type="button" className="btn btn-danger btn-sm float-right" onClick={this.handleDestroy}><i className="fa fa-trash"></i> Eliminar componente</button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor={`components[${this.props.id}][name]`}>{ this.props.canBeDeleted ? "Nombre del componente:" : "Nombre del producto final:" }</label>
                        <input type="text" name={`components[${this.props.id}][name]`} className="form-control" value={this.state.name} onChange={this.handleNameChange} />
                    </div>
                </div>
                <table className="table table-bordered table-striped table-responsive dt-responsive clever-table-sm">
                <thead>
                    <tr>
                        <th>Ingrediente</th>
                        <th>Gramos o ml<br/>(según corresponda)</th>
                        <th>Energía<br/>(Kcal)</th>
                        <th>Proteínas<br/>(g)</th>
                        <th>Grasa Total<br/>(g)</th>
                        <th>Grasa Saturada<br/>(g)</th>
                        <th>Grasa Monoinsaturada<br/>(g)</th>
                        <th>Grasa Poloinsaturada<br/>(g)</th>
                        <th>Grasa Trans<br/>(g)</th>
                        <th>Colesterol<br/>(mg)</th>
                        <th>H. de Carbono<br/>(g)</th>
                        <th>Azúcares totales<br/>(g)</th>
                        <th>Sodio<br/>(mg)</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.ingredients.map((item, key) => {
                        return <IngredientRowExtended key={item.id}
                            id={item.id}
                            name={item.name}
                            parentId={this.props.id}
                            gramsChangeHandler={this.handleGramsChange}
                            deleteHandler={this.handleRemoveIngredient}
                            grams={item.pivot.grams}
                            energy={this.getValueForProp(item.id, 'energy')}
                            protein={this.getValueForProp(item.id, 'protein')}
                            total_fat={this.getValueForProp(item.id, 'total_fat')}
                            sat_fat={this.getValueForProp(item.id, 'sat_fat')}
                            mono_fat={this.getValueForProp(item.id, 'mono_fat')}
                            poli_fat={this.getValueForProp(item.id, 'poli_fat')}
                            trans_fat={this.getValueForProp(item.id, 'trans_fat')}
                            cholesterol={this.getValueForProp(item.id, 'cholesterol')}
                            carbohydrates={this.getValueForProp(item.id, 'carbohydrates')}
                            sugar={this.getValueForProp(item.id, 'sugar')}
                            sodium={this.getValueForProp(item.id, 'sodium')} />
                    })}
                    <tr>
                        <td>
                            <div className="form-group">
                                <label>Ingrediente predeterminado:</label>
                                <select id="newIngredientId" className="form-control select2" style={{width: "200px"}} value={this.state.new.id} onChange={this.handleAddIngredientChange}>
                                    <option>Seleccione un ingrediente</option>
                                    {this.state.catalogue.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.name}{item.brand ? ` - ${item.brand}` : ''}</option>
                                    })}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>O agregue nuevo un ingrediente compuesto:</label>
                                <input type="text" id="newIngredientName" className="form-control" style={{width: "200px"}} value={this.state.new.name} onChange={this.handleAddIngredientChange} />
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleAddIngredientSubmit}>Agregar ingrediente</button>
                            </div>
                        </td>
                        <td><input type="number" id="newIngredientGrams" className="form-control" style={{width: "80px"}} value={this.state.new.grams} min="0" onChange={this.handleAddIngredientChange}/></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className="bg-dark text-white">
                        <th>{this.state.name}</th>
                        <th>Gramos o ml<br/>(según corresponda)</th>
                        <th>Energía<br/>(Kcal)</th>
                        <th>Proteínas<br/>(g)</th>
                        <th>Grasa Total<br/>(g)</th>
                        <th>Grasa Saturada<br/>(g)</th>
                        <th>Grasa Monoinsaturada<br/>(g)</th>
                        <th>Grasa Poloinsaturada<br/>(g)</th>
                        <th>Grasa Trans<br/>(g)</th>
                        <th>Colesterol<br/>(mg)</th>
                        <th>H. de Carbono<br/>(g)</th>
                        <th>Azúcares totales<br/>(g)</th>
                        <th>Sodio<br/>(mg)</th>
                    </tr>

                    <tr className="bg-dark text-white">
                        <td>Peso Neto Receta</td>
                        <td>
                            {this.state.totals.grams.toFixed(1)}
                            <input type="hidden" name={`components[${this.props.id}][final_grams]`} defaultValue={this.state.totals.final_grams} />
                        </td>
                        <td>{this.state.totals.energy.toFixed(1)}</td>
                        <td>{this.state.totals.protein.toFixed(1)}</td>
                        <td>{this.state.totals.total_fat.toFixed(1)}</td>
                        <td>{this.state.totals.sat_fat.toFixed(1)}</td>
                        <td>{this.state.totals.mono_fat.toFixed(1)}</td>
                        <td>{this.state.totals.poli_fat.toFixed(1)}</td>
                        <td>{this.state.totals.trans_fat.toFixed(1)}</td>
                        <td>{this.state.totals.cholesterol.toFixed(1)}</td>
                        <td>{this.state.totals.carbohydrates.toFixed(1)}</td>
                        <td>{this.state.totals.sugar.toFixed(1)}</td>
                        <td>{this.state.totals.sodium.toFixed(1)}</td>
                    </tr>

                    <tr>
                        <td className="bg-dark text-white">Peso Neto Final</td>
                        <td className="bg-dark text-white">
                            <input type="number" step="any" min="0" name={`components[${this.props.id}][final_grams]`} className="form-control" value={this.state.totals.final_grams} onChange={this.handleFinalGramsChange} />
                        </td>
                        <td className="bg-dark text-white" colSpan="999">
                            <p><em>En este cuadro se debe repetir el peso neto de su producto final obtenido de la receta total. Pero, si el proceso involucra cocción o evaporación de agua, deje enfriar,
                                    pese y luego complete con el valor final real obtenido por la receta.</em></p>
                        {
                            (this.state.weight_loss > 0) &&
                                <p>
                                    Existe pérdida de { parseFloat(this.state.weight_loss).toFixed(2) }% de peso
                                    por evaporación de agua durante
                                    la cocción.
                                </p>
                        }
                        </td>
                    </tr>
                </tfoot>
            </table>
            </div>
        </div>;
    }
}

export default ComponentTableExtended;
