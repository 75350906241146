require('./components/Product/Recipe');

document.addEventListener('DOMContentLoaded', () => {
    let elements = document.getElementsByClassName('is-rut');

    if (elements.length > 0) {
        let inputHandler = function () {
            if (this.value !== this.value.replace(/[^0-9\-k]/ig, '')) {
                this.value = this.value.replace(/[^0-9\-k]/ig, '');
            }
            if (this.value !== this.value.replace(/-/ig, '').replace(/([0-9])([0-9k])$/i, "$1-$2")) {
                this.value = this.value.replace(/-/ig, '').replace(/([0-9])([0-9k])$/i, "$1-$2");
            }
        };

        for(let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('blur', inputHandler);
            elements[i].addEventListener('keyup', inputHandler);
        }
    }
});
