import React from 'react';
import ReactDOM from 'react-dom';

class IngredientRow extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            grams: parseInt(this.props.grams)
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let value = event.target.value == "" || isNaN(event.target.value) ? 0 : parseInt(event.target.value);
        this.setState({ ...this.state, grams: value });
        this.props.gramsChangeHandler({id: this.props.id, grams: value});
    }

    render () {
        return <tr key={this.props.id}>
            <td>
                <a href="#" className="mr-2" onClick={this.props.deleteHandler} tabIndex="-1">
                    <i data-id={this.props.id} className="fa fa-minus-circle"></i>
                </a>
                <span>{this.props.name}</span>
            </td>
            <td><input type="number" name={`components[${this.props.parentId}][ingredients][${this.props.id}]`} className="form-control" style={{width: '80px'}} value={this.state.grams} onChange={this.handleChange} min="1" required="required" /></td>
        </tr>;
    }
}

export default IngredientRow;

if (document.getElementById('example')) {
    ReactDOM.render(<IngredientRow />, document.getElementById('example'));
}
