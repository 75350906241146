import React from 'react';
import ReactDOM from 'react-dom';
import ComponentTable from './ComponentTable';
import ComponentTableExtended from './ComponentTableExtended';
import Axios from 'axios';
import { getNewIngredient, getNewComponentId } from '../../models/Product';

class Recipe extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            finalProductId: this.props.finalProductId,
            components: [],
            ingredientsCatalogue: []
        };

        this.handleAddIngredient = this.handleAddIngredient.bind(this);
        this.handleDeleteIngredient = this.handleDeleteIngredient.bind(this);
        this.handleComponentUpdate = this.handleComponentUpdate.bind(this);
        this.handleDeleteComponent = this.handleDeleteComponent.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    handleAddIngredient(componentId, ingredient) {
        let components = this.state.components;
        for (let component of components) {
            if (component.id == componentId) {
                component.ingredients.push(ingredient);
            }
        }

        if (ingredient.is_component) {
            let { id, name, weight_loss, ingredients, is_component } = ingredient;
            components.push({
                id: id,
                name: name,
                weight_loss: weight_loss,
                is_component: is_component,
                ingredients: ingredients || []
            });
        }

        let catalogue = this.state.ingredientsCatalogue;
        let search = catalogue.filter(item => item.id == ingredient.id, ingredient);
        if (search.length == 0) {
            catalogue.push(ingredient);
        }

        this.setState({
            components: components,
            ingredientsCatalogue: catalogue
        });
    }

    handleDeleteIngredient(componentId, ingredientId) {
        let components = this.state.components;
        let stillUsed = false;
        for (let component of components) {
            if (component.id == componentId) {
                component.ingredients = [...component.ingredients].filter(ing => ing.id != ingredientId, ingredientId);
            } else {
                if (component.id != ingredientId) {
                    for (let ing of component.ingredients) {
                        if (ing.id == ingredientId) {
                            stillUsed = true;
                        }
                    };
                }
            }
        }

        if (!stillUsed) {
            components = [...this.state.components].filter(com => com.id != ingredientId, ingredientId);
        }

        this.setState({
            ...this.state,
            components: components
        });
    }

    handleComponentUpdate(id, props) {
        if (this.state.ingredientsCatalogue.length == 0) {
            return;
        }

        let grams = props.final_grams;

        let values = {
            energy: ((props.energy / grams) || 0) * 100,
            protein: ((props.protein / grams) || 0) * 100,
            total_fat: ((props.total_fat / grams) || 0) * 100,
            sat_fat: ((props.sat_fat / grams) || 0) * 100,
            mono_fat: ((props.mono_fat / grams) || 0) * 100,
            poli_fat: ((props.poli_fat / grams) || 0) * 100,
            trans_fat: ((props.trans_fat / grams) || 0) * 100,
            cholesterol: ((props.cholesterol / grams) || 0) * 100,
            carbohydrates: ((props.carbohydrates / grams) || 0) * 100,
            sugar: ((props.sugar / grams) || 0) * 100,
            sodium: ((props.sodium / grams) || 0) * 100,
        }

        let ingredients = this.state.ingredientsCatalogue.map(item => {
            if (item.id == id) {
                return Object.assign({}, item, values);
            } else {
                return item;
            }
        }, id, values);

        this.setState({  ingredientsCatalogue: ingredients });
    }

    handleDeleteComponent(id) {
        let components = this.state.components.filter(com => com.id != id, id);
        for (let component of components) {
            component.ingredients = component.ingredients.filter(item => item.id != id, id);
        }

        this.setState({ components: components });
    }

    async fetchProductComponents() {
        if (this.state.finalProductId != undefined && this.state.finalProductId > 0) {
            await Axios.get(`/dashboard/products/${this.state.finalProductId}/components`)
                        .then((response) => {
                            if (response.status === 200) {
                                let components = response.data;

                                if (this.props.templateId > 0) {
                                    let replacements = [];

                                    // update components id
                                    for (let component of components) {
                                        let newId = getNewComponentId();

                                        if (this.state.finalProductId > 0 && component.id === this.state.finalProductId) {
                                            this.setState({ ...this.state, finalProductId: newId });
                                        }

                                        replacements.push([component.id, newId]);
                                        component.id = newId;
                                    }
                                    // update ingredients references
                                    for (let component of components) {
                                        for (let ingredient of component.ingredients) {
                                            replacements.forEach((row,i) => {
                                                if (row[0] == ingredient.id) {
                                                    ingredient.id = row[1];
                                                }
                                            }, ingredient);
                                        }
                                    }
                                }

                                this.setState({ ...this.state, components: components });
                            }
                        });
        } else {
            let product = Object.assign({}, getNewIngredient());
            product.is_component = true;
            this.setState({ finalProductId: product.id, components: [ ...this.state.components, product ] });
        }
    }

    async fetchData() {
        let id = this.props.id || this.props.templateId;
        await Axios.get(`/dashboard/recipes/${id}/ingredients`)
                    .then((response) => {
                        if (response.status === 200) {
                            this.setState({ ...this.state, ingredientsCatalogue: response.data });
                        }

                        this.fetchProductComponents();
                    });
    }

    render () {
        if (this.state.components.length > 0) {
            return <div className="container mb-4">
                    <input type="hidden" name="finalProductId" value={this.state.finalProductId} />

                    {
                        this.state.components.map(item => {
                        return <div className="row" key={item.id}>
                            <div className="col">
                                {
                                    (window.extendedMode) ?
                                        <ComponentTableExtended
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        weight_loss={item.weight_loss}
                                        ingredients={item.ingredients}
                                        catalogue={this.state.ingredientsCatalogue}
                                        parentAddIngredient={this.handleAddIngredient}
                                        parentDeleteIngredient={this.handleDeleteIngredient}
                                        parentUpdateComponent={this.handleComponentUpdate}
                                        parentDeleteComponent={this.handleDeleteComponent}
                                        canBeDeleted={item.id != this.state.finalProductId} />
                                    :
                                        <ComponentTable
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        weight_loss={item.weight_loss}
                                        ingredients={item.ingredients}
                                        catalogue={this.state.ingredientsCatalogue}
                                        parentAddIngredient={this.handleAddIngredient}
                                        parentDeleteIngredient={this.handleDeleteIngredient}
                                        parentUpdateComponent={this.handleComponentUpdate}
                                        parentDeleteComponent={this.handleDeleteComponent}
                                        canBeDeleted={item.id != this.state.finalProductId} />
                                }
                            </div>
                        </div>
                        })
                    }
                </div>;
        } else {
            return <div className="alert">
                <p>Cargando...</p>
            </div>
        }
    }
}

export default Recipe;

if (document.getElementById('recipe-composition')) {
    ReactDOM.render(<Recipe id={currentRecipeId} finalProductId={finalProductId} templateId={templateId} />, document.getElementById('recipe-composition'));
}
