/* eslint-disable import/prefer-default-export */
export const getNewIngredient = () => {
    return {
        id: getNewComponentId(),
        name: 'Nuevo producto',
        company_id: 0,
        is_component: 1,
        recipe_id: 0,
        ingredients: [],
        pivot: {
            grams: 0,
        },
        weight_loss: 0.0,
        energy: 0.0,
        protein: 0.0,
        total_fat: 0.0,
        sat_fat: 0.0,
        mono_fat: 0.0,
        poli_fat: 0.0,
        trans_fat: 0.0,
        cholesterol: 0.0,
        carbohydrates: 0.0,
        sugar: 0.0,
        sodium: 0.0,
        brand: '',
        source: '',
    };
};

export function getNewComponentId()
{
    return (-1 * Math.floor(Math.random() * 100000));
}
